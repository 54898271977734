import { Observable } from 'rxjs';
import {
  NoWorkingDayCreateDto,
  NoWorkingDayDto,
  NoWorkingDayEditDto,
  NoWorkingDayPageDto,
  NoWorkingDayPageQueryParamsDto,
} from '../dtos/noworking-day.dto';

export abstract class NoWorkingDayRepository {
  abstract getPage(
    params: NoWorkingDayPageQueryParamsDto
  ): Observable<NoWorkingDayPageDto>;
  abstract create(workJob: NoWorkingDayCreateDto): Observable<NoWorkingDayDto>;
  abstract update(workJob: NoWorkingDayEditDto): Observable<NoWorkingDayDto>;
  abstract delete(workJob: { id: number }): Observable<NoWorkingDayDto>;
}
